<!--
 * @Description: 文件描述
-->
<template>
  <!-- <a-tree :tree-data="treeData" defaultExpandAll /> -->
  <a-tree show-line defaultExpandAll v-bind="$attrs" v-on="$listeners" :tree-data="realTreeData">
    <!-- <template #title="{ name, red }">
      <a-badge :dot="red">
      <span>{{ name }}</span>
      </a-badge>
    </template>-->
  </a-tree>
</template>

<script>
import { flatternData } from '../../_utils'
import { jsonToTree } from '@/utils/arrayUtil'

import _ from 'lodash'

export default {
  name: 'BuildingStructorTree',
  props: {
    treeData: {
      type: Object,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    realTreeData() {
      const _clonedTree = _.cloneDeep(this.treeData)
      _clonedTree.selectable = false // 项目名称节点不可选中
      const _listData = flatternData([_clonedTree])
      const _clonedData = _.cloneDeep(_listData)

      const newData = jsonToTree({
        list: _clonedData,
        fields: { idKey: 'key', pidKey: 'parentKey' }
      })

      newData[0].selectable = false // 项目名称节点不可选中
      return newData
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
